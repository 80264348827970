import Store from '@/store'
import index from '@/components/index'

import {
	createRouter,
	createWebHistory
} from 'vue-router'

const routerHistory = createWebHistory(process.env.BASE_URL)

export default createRouter({
	history: routerHistory,
	base: process.env.NODE_ENV === 'production' ? '/dist/' : '/',
	routes: [{
		name: 'index',
		path: '/',
		component: index,

		beforeEnter: (to, from, next) => {

			Store.dispatch('fetchPosts')
				.then(() => {
					next()
				})

		} /**/
		/*	*/
	}, ]
})