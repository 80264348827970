<template>
  <div class="index clearfix"></div>
</template>

<script>
export default {
  name: "index",
  mounted: function () {
    document.body.className = "home";
  },
  unmounted: function () {
    //document.body.className = "";
  },
};
</script>

<style scoped lang="scss">
$small: "only screen and (max-width: 764px)";
</style>
