<template>
  <header>
    <h1>
      <a href="#project">{{ maintitle }}</a>
      <span>{{ tokyotime }}</span>
    </h1>
    <div class="menu-container">
      <!--<div
        class="drawer"
        v-on:click="isActive = !isActive"
        v-bind:class="{ active: isActive }"
      >
        <span class="top"></span>
        <span class="bottom"></span>
      </div>
      <div class="menu" v-bind:class="{ open: isActive }">
        <ul v-on:click="isActive = false">
          <li class="about">
            <router-link to="/about">ABOUT</router-link>
          </li>
        </ul>
      </div>-->
    </div>
  </header>
</template>

<script>
import { ref } from "vue";
import { onMounted } from "vue";
export default {
  setup() {
    let maintitle = ref("");
    const date = new Date();
    let tokyotime = ref("");
    let onece = 1;
    let hh = date.getHours();
    let mm = date.getMinutes();
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    const time = hh + ":" + mm + " JST, UTC+9";

    class Typewriter {
      constructor(param) {
        this.speed = param.speed;
        this.txt = param.string;
        this.string = param.string.split("");
        this.delay = param.delay;
        this.el = param.el;
        this.offset = param.offset;
      }

      typeplus() {
        this.el.value = this.txt.substr(0, this.offset);
        this.string.forEach((char, index) => {
          setTimeout(() => {
            if (index > this.offset - 1) {
              this.el.value += char;
            }
          }, this.speed * (index + this.delay));
        });
      }

      typeminus() {
        this.el.value = this.txt;
        const limit = this.txt.length;
        this.string.forEach((char, index) => {
          setTimeout(() => {
            this.el.value = this.txt.substr(0, limit - index - 1 + this.offset);
          }, this.speed * (index + this.delay));
        });
      }
    }

    function doOnScroll() {
      if (window.scrollY > 0) {
        if (onece) {
          const typeTitle = new Typewriter({
            el: maintitle,
            string: "MAEDA DESIGN LLC.",
            speed: 20,
            delay: 0,
            offset: 1,
          });
          typeTitle.typeminus();

          const typeTime = new Typewriter({
            el: tokyotime,
            string: time,
            speed: 20,
            delay: 10,
            offset: 0,
          });
          typeTime.typeplus();
          onece = 0;
        }
      } else {
        if (!onece) {
          const typeTitle = new Typewriter({
            el: maintitle,
            string: "MAEDA DESIGN LLC.",
            speed: 20,
            delay: 10,
            offset: 1,
          });
          typeTitle.typeplus();

          const typeTime = new Typewriter({
            el: tokyotime,
            string: time,
            speed: 20,
            delay: 0,
            offset: 0,
          });
          typeTime.typeminus();

          onece = 1;
        }
      }
    }

    onMounted(() => {
      const typeMaintitle = new Typewriter({
        el: maintitle,
        string: "MAEDA DESIGN LLC.",
        speed: 20,
        delay: 50,
        offset: 0,
      });
      maintitle.value = "MAEDA DESIGN LLC.";
      setTimeout(() => {
        typeMaintitle.typeplus();
      }, 400);
    });
    window.addEventListener("scroll", doOnScroll, { passive: true });
    return {
      maintitle,
      tokyotime,
    };
  },
};
</script>
<style scoped lang="scss">
$small: "only screen and (max-width: 764px)";

header {
  position: fixed;
  left: 30px;
  bottom: 10px;
  z-index: 9999;
  //background: #fff;
  @media #{$small} {
    left: 16px;
  }
}
h1 {
  font-size: 42px;
  z-index: 100;
  color: #fff;
  line-height: 1;
  margin: 0;
  font-weight: bold;
  letter-spacing: -0.02em;
  @media #{$small} {
    font-size: 32px;
  }
  a {
    text-decoration: none;
    margin-right: 20px;
    display: inline-block;
    @media #{$small} {
      margin-right: 10px;
    }
  }
  span {
    font-size: 24px;
    display: inline-block;
    transform: translateY(-10px);
    @media #{$small} {
      font-size: 18px;
    }
  }
  @media #{$small} {
    right: 60px;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.menu {
  position: fixed;
  margin: 0;
  top: 20px;
  right: 10px;
  width: 400px;
  padding: 0;
  display: inline-block;
  z-index: 100;
  text-align: right;
  line-height: 1;

  @media #{$small} {
    display: none;
    background-color: #000;
    mix-blend-mode: normal;
  }
  ul {
    margin: 0;
  }
  li {
    display: inline;
    padding: 0 10px;
    font-size: 28px;
    letter-spacing: 0.02em;
    a {
      color: #fff;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.drawer {
  display: none;
  position: fixed;
  right: 0px;
  top: 0;
  z-index: 9999;
  width: 40px;
  height: 40px;
  cursor: pointer;
  @media #{$small} {
    display: block;
  }
  span {
    width: 30px;
    height: 2px;
    display: block;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
  }
  .top {
    animation: top-open 0.3s;
    animation-fill-mode: forwards;
  }
  .bottom {
    animation: bottom-open 0.3s;
    animation-fill-mode: forwards;
  }
}

.drawer.active {
  span {
    background: #fff;
  }
  .top {
    animation: top-close 0.3s;
    animation-fill-mode: forwards;
  }

  .bottom {
    animation: bottom-close 0.3s;
    animation-fill-mode: forwards;
  }
}

.menu.open {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #ff4800;
  ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;

    li {
      text-align: center;
      display: block;
      margin-bottom: 10px;
    }
  }
  a {
    color: #fff;
  }
  .drawer_outer {
    transform: translateX(-200px);
  }

  .drawer-menu {
    transform: translateX(-200px);
  }

  .wrapper {
    transform: translateX(-200px);
  }
}

@keyframes top-open {
  0% {
    transform: translateY(0px) rotate(-45deg);
  }

  50% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(-4px) rotate(0deg);
  }
}

@keyframes bottom-open {
  0% {
    transform: translateY(-1px) rotate(45deg);
  }

  50% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(4px) rotate(0deg);
  }
}

@keyframes top-close {
  0% {
    transform: translateY(-4px) rotate(0deg);
  }

  50% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(0px) rotate(-45deg);
  }
}

@keyframes bottom-close {
  0% {
    transform: translateY(4px) rotate(0deg);
  }

  50% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(-1px) rotate(45deg);
  }
}
</style>