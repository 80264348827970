<template>
  <footer></footer>
</template>

<style scoped lang="scss">
$small: "only screen and (max-width: 764px)";

footer {
  font-size: 12px;
  letter-spacing: 0.04em;
  z-index: 4;
  font-weight: bold;
  color: #fff;
  background-color: #908978;
  margin: 1px 0;
  padding: 30px;
  border-radius: 4px;
  text-align: right;
  min-height: 80px;

  @media #{$small} {
    min-height: 60px;
  }
}
</style>
