import {
	createStore
} from 'vuex'

import axios from 'axios'


const api = axios.create({
	//baseURL: 'http://localhost/'
	baseURL: '/w/'
});

export default createStore({
	namespaced: true,
	state: {
		posts: [],
		details: []
	},
	getters: {
		getposts(state) {
			return state.posts
		},
		getdetails(state) {
			return state.details
		}
	},
	mutations: {
		SAVE_POSTS(state, data) {
			state.posts = data
		},
		SAVE_DETAILS(state, data) {
			state.details = data
		}
	},
	actions: {
		async 'fetchPosts'({
			commit
		}, id) {
			const _url = id ? `wp-json/wp/v2/posts/${id}?_embed` : 'wp-json/custom/posts';

			if (id) {
				return await api.get(`${_url}`)
					.then(res => {
						commit('SAVE_DETAILS', res.data);
					})
					.catch(function (error) {
						console.log(error);
						//alert(error);
					});
			} else {
				return await api.get(`${_url}`)
					.then(res => {
						commit('SAVE_POSTS', res.data);
					})
					.catch(function (error) {
						console.log(error);
						//alert(error);
					});
			}
		},
	}

})

//http://0.0.0.0:6001/wp-json/wp/v2/works
//https://mdllc.jp/w/