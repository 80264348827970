<template>
  <div class="main__wrapper">
    <myheader></myheader>
    <div class="scroll_wrapper">
      <div class="scroll_inner">
        <div id="container" v-bind:class="{ open: isActive }">
          <div class="firstview__content">
            <p class="description">
              <span>{{ description }}</span>
            </p>
            <p class="description2">
              <span v-html="description2"></span> <span>{{ copy }}</span>
            </p>
            <p class="contact__block">
              <span class="projects" id="projects">{{ projects }}</span>
              <a
                href="http://instagram.com/maeda_design_llc"
                target="_blank"
                class="instagram"
                >{{ instagram }}</a
              >
              <a v-bind:href="'mailto:' + email" class="contact">{{
                contact
              }}</a>
            </p>
            <p class="last"></p>
          </div>
          <div class="header__message">
            <span>{{ msg }}</span>
          </div>
          <myindex></myindex>
          <myfooter></myfooter>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//npm run serve
import myheader from "./components/myheader";
import myfooter from "./components/myfooter";
import myindex from "./components/worklist";
import { nextTick, onMounted, onUnmounted, defineComponent } from "vue";
import { ref } from "vue";

export default defineComponent({
  name: "App",
  components: {
    myheader,
    myfooter,
    myindex,
  },
  setup() {
    let description = ref("");
    let description2 = ref("");
    let contact = ref("");
    let instagram = ref("");
    let projects = ref("");
    let copy = ref("");
    const email = "info@mdllc.jp";

    let msg = ref("");
    //typewriter

    class Typewriter {
      constructor(param) {
        this.speed = param.speed;
        this.string = param.string.split("");
        this.delay = param.delay;
        this.el = param.el;
      }
      typeplus() {
        this.string.forEach((char, index) => {
          setTimeout(() => {
            this.el.value += char;
          }, this.speed * (index + this.delay));
        });
      }
    }
    //慣性スクロール
    class MomentumScroll {
      constructor(selector) {
        this.container = document.querySelector(selector);
        this.scrollY = 0;
        this.translateY = 0;
        this.speed = 0.2;
        this.rafId = null;
        this.isActive = false;

        this.scrollHandler = this.scroll.bind(this);
        this.resizeHandler = this.resize.bind(this);

        this.run();
      }

      run() {
        if (this.isActive) {
          return;
        }

        this.isActive = true;

        this.on();
        this.setStyles();
      }

      destroy() {
        if (!this.isActive) {
          return;
        }

        this.isActive = false;

        this.off();
        this.clearStyles();

        if (this.rafId) {
          cancelAnimationFrame(this.rafId);
        }

        this.rafId = null;
      }

      resize() {
        document.querySelector(
          ".scroll_inner"
        ).style.height = `${this.container.clientHeight}px`;
      }

      scroll() {
        this.scrollY = window.scrollY || window.pageYOffset;

        if (!this.rafId) {
          this.container.style.willChange = "transform";
          this.rafId = requestAnimationFrame(() => this.render());
        }
      }

      on() {
        this.resize();
        this.scroll();
        window.addEventListener("scroll", this.scrollHandler, {
          passive: true,
        });
        window.addEventListener("resize", this.resizeHandler);
        window.addEventListener("load", this.resizeHandler);
      }

      off() {
        window.removeEventListener("scroll", this.scrollHandler);
        window.removeEventListener("resize", this.resizeHandler);
        window.removeEventListener("load", this.resizeHandler);
      }

      setStyles() {
        this.container.style.position = "fixed";
        this.container.style.width = "100%";
        this.container.style.top = 0;
        this.container.style.left = 0;
      }

      clearStyles() {
        document.querySelector(".scroll_inner").style.height = "";
        this.container.style.position = "";
        this.container.style.width = "";
        this.container.style.top = "";
        this.container.style.left = "";
        this.container.style.transform = "";
        this.container.style.willChange = "";
      }

      render() {
        const nextY =
          this.translateY + (this.scrollY - this.translateY) * this.speed;
        const isNear = Math.abs(this.scrollY - nextY) <= 0.1;
        this.translateY = isNear ? this.scrollY : nextY;
        const roundedY = Math.round(this.translateY * 100) / 100;
        this.container.style.transform = `translate3d(0, -${roundedY}px, 0)`;
        if (isNear) {
          this.rafId = null;
          this.container.style.willChange = "";
        } else {
          this.rafId = requestAnimationFrame(() => this.render());
        }
      }
      scrollto() {
        const scrollh = document.querySelector(
          ".firstview__content"
        ).clientHeight;
        scrollTo(0, scrollh);
      }
    }

    nextTick(() => {});

    onMounted(() => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      const momentumScroll = new MomentumScroll("#container");
      momentumScroll;

      document
        .getElementById("projects")
        .addEventListener("click", momentumScroll.scrollto);

      const typeDescription = new Typewriter({
        el: description,
        string:
          "We are a Tokyo-based design studio that draws on the power of design, discussion, and strategic branding to provide holistic solutions in partnership with corporations, organizations, and individuals. We craft images, stories, and platforms informed by research that harnesses the zeitgeist to pave the way for the culture of tomorrow. Above all, we are committed to providing clients with a unique perspective that transcends the contours of convention.",
        speed: 2,
        delay: 0,
      });
      typeDescription.typeplus();

      const typeDescription2 = new Typewriter({
        el: description2,
        string:
          "MAEDA DESIGN LLC. was founded in 2012 by <a href='http://akinobumaeda.com' target='_blank'>Akinobu Maeda</a>.",
        speed: 10,
        delay: 60,
      });
      typeDescription2.typeplus();

      const typeCopy = new Typewriter({
        el: copy,
        string: "© 2022 MAEDA DESIGN LLC.",
        speed: 10,
        delay: 160,
      });
      typeCopy.typeplus();

      const Projects = new Typewriter({
        el: projects,
        string: "Projects",
        speed: 10,
        delay: 80,
      });
      Projects.typeplus();

      const Instagram = new Typewriter({
        el: instagram,
        string: "Instagram",
        speed: 10,
        delay: 100,
      });
      Instagram.typeplus();

      const Contact = new Typewriter({
        el: contact,
        string: "Contact",
        speed: 10,
        delay: 100,
      });
      Contact.typeplus();

      msg.value =
        "Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← Swipe sideways ← ";
      const msg_scroll = () => {
        msg.value =
          msg.value.substring(1, msg.value.length) + msg.value.substring(0, 1);
        setTimeout(msg_scroll, 200);
      };
      msg_scroll();

      //color

      var colorArray = new Array(
        "color1",
        "color2",
        "color3",
        "color4",
        "color5"
      );
      var color = colorArray[Math.floor(Math.random() * colorArray.length)];
      document.body.classList.add(color);
    });

    onUnmounted(() => {
      this.destroy();
    });

    return {
      msg,
      email,
      contact,
      instagram,
      projects,
      description,
      description2,
      copy,
    };
  },
});
</script>

<style lang="scss">
$small: "only screen and (max-width: 764px)";

@font-face {
  font-family: "nt-dapper-medium";
  src: url("~@/assets/font/NTDapper-Medium.woff2") format("woff2"),
    url("~@/assets/font/NTDapper-Medium.woff") format("woff"),
    url("~@/assets/font/NTDapper-Medium.eot");
  font-display: swap;
}
@font-face {
  font-family: "nt-dapper-bold";
  src: url("~@/assets/font/NTDapper-Bold.woff2") format("woff2"),
    url("~@/assets/font/NTDapper-Bold.woff") format("woff"),
    url("~@/assets/font/NTDapper-Bold.eot");
  font-display: swap;
}

html {
  width: 100%;
  height: 100%;
}
body {
  background-color: #fff;
  font-family: "nt-dapper-bold", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  margin: 0;
  padding: 0;
  letter-spacing: -0.01em;
  //  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
.scroll_wrapper {
}
.scroll_inner {
  position: relative;
  text-align: center;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
a {
  color: #fff;
}

.color1 {
  .header__message {
    color: #8bc19e;
  }
  .firstview__content {
    p {
      &:after {
        background-color: #8bc19e;
      }
    }
  }
  footer {
    background-color: #8bc19e;
  }
  .information,
  .bg {
    background-color: #8bc19e;
  }
}
.color2 {
  .header__message {
    color: #d6b194;
  }
  .firstview__content {
    p {
      &:after {
        background-color: #d6b194;
      }
    }
  }
  footer {
    background-color: #d6b194;
  }
  .information,
  .bg {
    background-color: #d6b194;
  }
}
.color3 {
  .header__message {
    color: #c6bf9a;
  }
  .firstview__content {
    p {
      &:after {
        background-color: #c6bf9a;
      }
    }
  }
  footer {
    background-color: #c6bf9a;
  }
  .information,
  .bg {
    background-color: #c6bf9a;
  }
}
.color4 {
  .header__message {
    color: #629db0;
  }
  .firstview__content {
    p {
      &:after {
        background-color: #629db0;
      }
    }
  }
  footer {
    background-color: #629db0;
  }
  .information,
  .bg {
    background-color: #629db0;
  }
}
.color5 {
  .header__message {
    color: #cfa9b3;
  }
  .firstview__content {
    p {
      &:after {
        background-color: #cfa9b3;
      }
    }
  }
  footer {
    background-color: #cfa9b3;
  }
  .information,
  .bg {
    background-color: #cfa9b3;
  }
}
.firstview__content {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  line-height: 1.1;
  text-align: left;
  color: #fff;
  display: flex;
  flex-direction: column;

  p {
    margin: 1px 0;
    padding: 30px;
    border-radius: 4px;
    min-height: 80px;
    position: relative;
    overflow: hidden;
    letter-spacing: 0.01em;

    &:nth-child(2) {
      display: flex;
      align-items: center;
      &:after {
        animation-delay: 0.2s;
      }
    }
    &:nth-child(3) {
      position: relative;
      display: flex;
      align-items: center;

      .projects {
        display: inline-block;
        margin-right: 20px;
        text-decoration: underline;
        cursor: pointer;
      }
      .instagram {
        display: inline-block;
        margin-right: 20px;
      }
      .contact {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
      }
      &:after {
        animation-delay: 0.3s;
      }
    }
    &:nth-child(4) {
      &:after {
        animation-delay: 0.4s;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0%;
      z-index: -1;
      animation: fadeIn 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }

    @keyframes fadeIn {
      0% {
        height: 0%;
      }
      100% {
        height: 100%;
      }
    }

    @media #{$small} {
      padding: 16px;
      min-height: 64px;
    }
    &.description {
      font-size: 52px;
      flex: 1;
      letter-spacing: -0.02em;

      @media only screen and (max-width: 1300px) {
        font-size: 46px;
      }
      @media only screen and (max-width: 1160px) {
        font-size: 44px;
      }
      @media only screen and (max-width: 1060px) {
        font-size: 42px;
      }
      @media only screen and (max-width: 880px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 800px) {
        font-size: 38px;
      }
      @media #{$small} {
        font-size: 28px;
      }
      @media only screen and (max-height: 740px) {
        font-size: 24px;
      }
    }
    &.description2 {
      font-size: 16px;
      align-items: center;
      line-height: 1.4;
      @media #{$small} {
        font-size: 12px;
        flex-direction: column;
        align-items: flex-start;
      }
      span {
        display: inline-block;
        margin-right: 2em;
        text-align: left;
        @media #{$small} {
          margin-right: 0;
        }
      }
    }
    &.contact__block {
      font-size: 16px;
      @media #{$small} {
        font-size: 12px;
      }
    }
    &.last {
      margin-bottom: 0;
    }
  }
}

.header__message {
  margin-top: 2px;
  padding: 20px 0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  @media #{$small} {
    font-size: 12px;
    padding: 10px 0;
  }
  span {
    display: block;
    white-space: nowrap;
  }
  /*
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0%;
    background-color: #908978;
    z-index: -1;
    animation: fadeIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }*/
}
.transition-enter-from,
.transition-leave-to {
  // transform: translateX(100%);
  opacity: 0;
}

.transition-enter-active,
.transition-leave-active {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.transition-enter-to,
.transition-leave-from {
  transform: translateX(0%);
  opacity: 1;
}

/*
.transition-enter-from,
.transition-leave-to {

}

.transition-enter-active,
.transition-leave-active {
  transform: translateX(0%);
  opacity: 1;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}
*/

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.back {
  position: fixed;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  @media #{$small} {
    top: auto;
    bottom: 10px;
    transform: translateY(0);
  }
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

/*
.work_wrapper {
  transform: translateX(-100%);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}*/
</style>
