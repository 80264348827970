<template>
  <div class="wrapper">
    <main class="main">
      <!--v-if="posts && posts.length"-->
      <transition-group
        tag="div"
        class="post_list"
        name="list"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @enter-cancelled="afterEnter"
        appear
      >
        <article
          class="post-item"
          v-for="(post, index) in posts"
          v-bind:key="post.id"
          v-on:mouseenter="mouseOver(index)"
          v-on:mouseleave="mouseLeave(index)"
        >
          <!--<router-link :to="`/works/${post.id}`">-->
          <h3 v-html="post.title" v-if="post.description"></h3>
          <button
            class="show_detail"
            @click="toggle(index)"
            v-on:mouseenter="!isMobile ? toggle(index) : null"
            v-on:mouseleave="!isMobile ? toggleout() : null"
            v-if="post.description"
            aria-label="Show detail information"
          ></button>

          <swiper
            :slides-per-view="1"
            :modules="modules"
            :speed="200"
            navigation
            :pagination="{
              type: 'fraction',
            }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiperSlide
              v-for="(value, index) in post.imageurl"
              class="post-image"
              v-bind:key="index"
            >
              <div class="work-bg">
                <video
                  class="thum lazyload"
                  v-if="value.includes('.mp4')"
                  loop
                  muted
                  playsinline
                  data-autoplay
                  v-bind:poster="post.thum[index]"
                  v-bind:data-poster="post.thumsp[index]"
                >
                  <source
                    v-if="isMobile && post.imageurlsp[index]"
                    v-bind:src="post.imageurlsp[index]"
                    type="video/mp4"
                  />
                  <source v-else v-bind:src="value" />
                </video>
                <img
                  v-if="!value.includes('.mp4')"
                  v-bind:src="post.thum[index]"
                  v-bind:data-srcset="
                    post.thumsp[index] + ' 767w, ' + value + ' 2000w '
                  "
                  data-sizes="auto"
                  class="thum lazyload"
                  v-bind:alt="post.title"
                />
              </div>
            </swiperSlide>
          </swiper>

          <div class="post-item__inner">
            <transition>
              <div class="information" v-show="!show_contents.indexOf(index)">
                <p class="description" v-html="post.description"></p>
                <div class="information__detail">
                  <dl v-if="post.client">
                    <dt>Client:</dt>
                    <dd v-html="post.client"></dd>
                  </dl>
                  <dl v-if="post.cat">
                    <dt>Category:</dt>
                    <dd v-html="post.cat"></dd>
                  </dl>
                  <dl v-if="post.year">
                    <dt>Year:</dt>
                    <dd v-html="post.year"></dd>
                  </dl>
                  <h2 v-html="post.title"></h2>
                </div>
              </div>
            </transition>
            <div class="bg"></div>
          </div>

          <!--</router-link>-->
        </article>
      </transition-group>
    </main>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";

import * as lazysizes from "lazysizes";

//lazysizes.cfg.preloadAfterLoad = true;

import { Pagination, Navigation, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

//import "swiper/swiper-bundle.css";
//import * as Vibrant from "node-vibrant";
import { ref } from "vue";

export default {
  name: "list",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    // const posts = ref([]);
    const show_contents = ref([]);
    let isMobile = true;
    const store = useStore();
    const posts = store.getters.getposts;

    /* */
    if (
      navigator.userAgent.indexOf("iPhone") > 0 ||
      (navigator.userAgent.indexOf("Android") > 0 &&
        navigator.userAgent.indexOf("Mobile") > 0)
    ) {
      isMobile = true;
    } else if (
      navigator.userAgent.indexOf("iPad") > 0 ||
      navigator.userAgent.indexOf("Android") > 0
    ) {
      isMobile = true;
    } else {
      isMobile = false;
    }

    //show contens

    const toggle = (index) => {
      if (show_contents.value.indexOf(index) >= 0) {
        show_contents.value = show_contents.value.filter((n) => n !== index);
      } else {
        show_contents.value = [];
        show_contents.value.push(index);
      }
    };

    const toggleout = () => {
      show_contents.value = [];
    };

    const mouseOver = () => {};

    const mouseLeave = () => {};

    onMounted(() => {
      /*
      const bg = document.getElementsByClassName("bg");
      const information = document.getElementsByClassName("information");

      posts.forEach(function (v, i) {
        Vibrant.from(posts[i].thum[0])
          .getPalette()
          .then(function (palette) {
            console.log(palette, bg, information);
          
            bg[i].style.backgroundColor =
              "rgb(" +
              palette.DarkMuted._rgb[0] +
              "," +
              palette.DarkMuted._rgb[1] +
              "," +
              palette.DarkMuted._rgb[2] +
              ")";
            information[i].style.backgroundColor =
              "rgb(" +
              palette.DarkMuted._rgb[0] +
              "," +
              palette.DarkMuted._rgb[1] +
              "," +
              palette.DarkMuted._rgb[2] +
              ")";
          })
          .catch(function (value) {
            console.log(value);
          });
      });
*/
      // IntersectionObserver

      /*
      const videolist = document.querySelectorAll("video");
      videolist.forEach((video) => {
        video.addEventListener(
          "pause",
          function () {
            console.log("一時停止");
          },
          true
        );
      });
*/

      const targets = document.querySelectorAll(".post-item");
      const options = {
        threshold: 0,
      };
      const observer = new IntersectionObserver(showElements, options);
      targets.forEach((target) => {
        observer.observe(target);
      });
      function showElements(entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            entry.target.classList.add("active");

            if (entry.target.querySelectorAll("video")) {
              const videos = entry.target.querySelectorAll("video");
              videos.forEach((video) => {
                video.play();
              });
            } /**/
          } else {
            entry.target.classList.remove("active");

            if (entry.target.querySelectorAll("video")) {
              const videos = entry.target.querySelectorAll("video");
              videos.forEach((video) => {
                video.pause();
              });
            } /**/
          }
        });
      }
    });
    const afterEnter = ($el) => {
      $el.querySelector(".bg").style.transitionDelay = "";
      $el.querySelector(".swiper").classList.add("show");
    };
    return {
      posts,
      //beforeEnter,
      afterEnter,
      mouseOver,
      mouseLeave,
      lazysizes,
      toggle,
      toggleout,
      isMobile,
      show_contents,
      modules: [Pagination, Navigation, EffectFade],
    };
  },
};
</script>

<style lang="scss">
$small: "only screen and (max-width: 764px)";

.lazyload {
  filter: blur(20px);
}
.lazyloaded {
  filter: blur(0);
}

.wrapper {
  //overflow-y: scroll;
  width: 100%;
  // position: absolute;
  //padding-top: 64px;
}
main {
  margin-left: -2px;
  margin-right: -2px;
  padding: 0 1px 0px;

  .post_list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .post-item {
    padding: 2px 1px 0;
    font-size: 0;
    position: relative;
    width: 50%;

    &.active {
      .swiper {
        opacity: 1;
      }
    }
    .swiper {
      opacity: 0;
      transition: all 0.4s 0s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .post-image {
      border-radius: 4px;
      overflow: hidden;
      padding-top: 66%;
    }
    .work-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .thum {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: translateZ(0px);
      transition: opacity 0.2s 0.1s cubic-bezier(0.77, 0, 0.175, 1);
      backface-visibility: hidden;
    }

    @media #{$small} {
      width: 100%;
    }

    &:hover {
    }
    h3 {
      position: absolute;
      left: 20px;
      bottom: 16px;
      color: #fff;
      font-size: 12px;
      z-index: 1010;
      margin: 0;
      //mix-blend-mode: difference;
      @media #{$small} {
        font-size: 10px;
      }
    }
    h5 {
      font-size: 12px;
      position: absolute;
      z-index: 10000;
      font-size: 26px;
      color: #fff;
    }
    .show_detail {
      width: 32px;
      height: 32px;
      appearance: none;
      display: block;
      border: none;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1080;
      cursor: pointer;
      background: transparent;

      &:after {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: #fff;
        //mix-blend-mode: difference;
        border-radius: 7px;
      }
    }
    .information {
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

      display: block;
      margin: 0;
      color: #fff;
      z-index: 1060;
      font-weight: medium;
      position: absolute;
      top: 2px;
      bottom: 0;
      left: 1px;
      right: 1px;
      border-radius: 2px;

      .description {
        padding: 20px;
        font-size: 18px;
        text-align: left;
        margin: 0;
        @media #{$small} {
          font-size: 13px;
        }
      }
      .information__detail {
        position: absolute;
        left: 20px;
        bottom: 16px;
        text-align: left;
        h2 {
          margin: 0;
          padding: 0;
          font-size: 12px;
          line-height: 1.2;
          font-weight: normal;
          @media #{$small} {
            font-size: 10px;
          }
        }
      }
      dl {
        font-size: 12px;
        text-align: left;
        line-height: 1.2;
        margin: 0;
        //opacity: 0;
        @media #{$small} {
          font-size: 10px;
        }
        dt {
          display: inline;
          width: auto;
        }
        dd {
          display: inline;
          margin-left: 0.5em;
        }
      }
    }

    .bg {
      position: absolute;
      top: 2px;
      bottom: 0;
      left: 1px;
      right: 1px;
      border-radius: 3px;
    }

    a {
      display: block;
      width: 100%;
    }

    .work_thum {
      width: 100%;
      height: auto;
    }

    .post-inner {
      position: relative;
    }
  }

  li:before {
    content: "";
    display: block;
    width: 1em;
    height: 1px;
    background-color: #000;
    position: absolute;
    left: -2em;
    top: 1.2em;
  }
  .swiper {
    z-index: 1002;
    .swiper-pagination-fraction {
      font-size: 13px;
      bottom: 16px;
      right: 50px;
      left: auto;
      width: auto;
      color: #fff;
      display: inline-block;
      // mix-blend-mode: difference;
    }

    .swiper-pagination-lock {
      display: none;
    }
    .swiper-button-prev {
      background-image: url("../assets/pre.svg");
      left: auto;
      right: 94px;
    }
    .swiper-button-next {
      background-image: url("../assets/next.svg");

      right: 20px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      z-index: 1001;
      bottom: 18px;
      height: 10px;
      top: auto;
      width: 18px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      //mix-blend-mode: difference;

      &:after {
        display: none;
      }
      &.swiper-button-disabled {
        display: none;
      }
    }
  }
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translateZ(0);
}

.swiper-pointer-events {
  touch-action: pan-y;
}

.swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-autoheight,
.swiper-autoheight .swiper-slide {
  height: auto;
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-3d,
.swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px;
}

.swiper-3d .swiper-cube-shadow,
.swiper-3d .swiper-slide,
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-wrapper {
  transform-style: preserve-3d;
}

.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-3d .swiper-slide-shadow {
  background: rgba(0, 0, 0, 0.15);
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}

.swiper-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-horizontal.swiper-css-mode > .swiper-wrapper {
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

.swiper-vertical.swiper-css-mode > .swiper-wrapper {
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}

.swiper-centered > .swiper-wrapper:before {
  content: "";
  flex-shrink: 0;
  order: 9999;
}

.swiper-centered.swiper-horizontal
  > .swiper-wrapper
  > .swiper-slide:first-child {
  -webkit-margin-start: var(--swiper-centered-offset-before);
  margin-inline-start: var(--swiper-centered-offset-before);
}

.swiper-centered.swiper-horizontal > .swiper-wrapper:before {
  height: 100%;
  min-height: 1px;
  width: var(--swiper-centered-offset-after);
}

.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  -webkit-margin-before: var(--swiper-centered-offset-before);
  margin-block-start: var(--swiper-centered-offset-before);
}

.swiper-centered.swiper-vertical > .swiper-wrapper:before {
  width: 100%;
  min-width: 1px;
  height: var(--swiper-centered-offset-after);
}

.swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
}

.swiper-virtual .swiper-slide {
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}

.swiper-virtual.swiper-css-mode .swiper-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.swiper-virtual.swiper-css-mode.swiper-horizontal .swiper-wrapper:after {
  height: 1px;
  width: var(--swiper-virtual-size);
}

.swiper-virtual.swiper-css-mode.swiper-vertical .swiper-wrapper:after {
  width: 1px;
  height: var(--swiper-virtual-size);
}

:root {
  --swiper-navigation-size: 44px;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: normal;
  line-height: 1;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: opacity 0.3s;
  transform: translateZ(0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  );
  display: inline-block;
  border-radius: 50%;
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet:only-child {
  display: none !important;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  display: inline-block;
  transition: transform 0.2s, top 0.2s;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}

.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: transform 0.2s, left 0.2s;
}

.swiper-horizontal.swiper-rtl
  > .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: transform 0.2s, right 0.2s;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}

.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}

.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
.swiper-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-horizontal
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  box-sizing: border-box;
  border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
  border-radius: 50%;
  border-top-color: transparent;
}

.swiper-slide-visible .swiper-lazy-preloader {
  -webkit-animation: swiper-preloader-spin 1s linear infinite;
  animation: swiper-preloader-spin 1s linear infinite;
}

.swiper-lazy-preloader-white {
  --swiper-preloader-color: #fff;
}

.swiper-lazy-preloader-black {
  --swiper-preloader-color: #000;
}

@-webkit-keyframes swiper-preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes swiper-preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.swiper .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-grid > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-grid-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}

.swiper-fade.swiper-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}

.swiper-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-fade .swiper-slide-active,
.swiper-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-cube {
  overflow: visible;
}

.swiper-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-cube.swiper-rtl .swiper-slide {
  transform-origin: 100% 0;
}

.swiper-cube .swiper-slide-active,
.swiper-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-cube .swiper-slide-active,
.swiper-cube .swiper-slide-next,
.swiper-cube .swiper-slide-next + .swiper-slide,
.swiper-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-cube .swiper-slide-shadow-bottom,
.swiper-cube .swiper-slide-shadow-left,
.swiper-cube .swiper-slide-shadow-right,
.swiper-cube .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: 0;
}

.swiper-cube .swiper-cube-shadow:before {
  content: "";
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  filter: blur(50px);
}

.swiper-flip {
  overflow: visible;
}

.swiper-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-flip .swiper-slide-active,
.swiper-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-flip .swiper-slide-shadow-bottom,
.swiper-flip .swiper-slide-shadow-left,
.swiper-flip .swiper-slide-shadow-right,
.swiper-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-creative .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  transition-property: transform, opacity, height;
}

.swiper-cards {
  overflow: visible;
}

.swiper-cards .swiper-slide {
  transform-origin: center bottom;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}
@media #{$small} {
  main {
    article {
      float: none;
      width: 100%;
      padding: 20px;
    }
  }
}
</style>
